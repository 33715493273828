canvas {
    align:center
}

IonBadge {
    position:absolute !important;
    left: 10px;
    top: 200!important;
    color: purple
}

ion-fab {
    display: flex;
    height: 100%;
    flex-direction: column;
    vertical-align: middle;
}

ion-badge.grassy {
    position: absolute;
    top: 30%;
    vertical-align: middle !important;
    text-orientation: upright;
    writing-mode: vertical-rl;
    padding: 5px;
    background-color: green;
    border-radius: 5px;
    border-style: solid;
}

ion-badge.shelter {
    position: absolute;
    top: 30%;
    right: 15px;
    vertical-align: middle !important;
    text-orientation: upright;
    writing-mode: vertical-rl;
    padding: 5px;
    background-color: green;
    border-radius: 5px;
    border-style: solid;
}

img {
    padding: 2px;
    padding-top: 0px;
    height:30px;
    float: left;
}

.fl {
    float: left;
    vertical-align: middle !important;
    margin-left: 1px;
    margin-top: 4px;
}

ion-header ion-toolbar  ion-title {
        font-size: 1.2em;    
        font-weight: 600;
        align-content: center;
        padding-left: 5px;
}

ion-header ion-toolbar  ion-card-subtitle {
    font-size: 1.1em;    
}

ion-toolbar {
    padding-left: 14px;
}


ion-header ion-toolbar {
    background-color: (120, 100%, 76%) !important;
}


ion-modal ion-header ion-toolbar ion-title {
    font-size: 1.3em;
    padding-left: 10px;
    font-weight: 600;
    text-align: left;
}   



ion-icon {
    font-size: 3em;
}


div.vendor-details {
    margin-top: 2px !important;
    margin-left: 5px;
    padding: 10px !important;  
    font-size: 1.3em;
    font-size-adjust: 0.5;
}


ion-modal {
    filter: brightness(120%);
}

ion-header { 
    filter: brightness(82%);
    font-weight: 900;
}

div.vendor-header {
    background-color: orange !important;
}

button > span ion-icon {
    font-size: 30em;
}
